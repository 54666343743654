/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
import {
  storesCollection,
  usersCollection,
  firebase,
} from '@/firebase'
import { set } from 'vuex-intern'
import Vue from 'vue'

import { manageAbility } from '@/libs/acl/config'

const state = {
  storesList: [],
}

const getters = {
  storesList: state => state.storesList,
}

const mutations = {
  setStores: set('storesList'),
}

const actions = {
  fetchStoreInfo: async (ctx, query) => new Promise((resolve, reject) => {
    storesCollection.where('search', 'array-contains', query).then(store => {
      if (store.exists) {
        resolve(store.data())
      } else {
        resolve(null)
      }
    }).catch(error => {
      reject(error)
    })
  }),
  getUserInfo: (ctx, email) => new Promise((resolve, reject) => {
    usersCollection.where('email', '==', email).get().then(snapshot => {
      if (snapshot.empty) {
        resolve(null)
      } else {
        resolve(snapshot.docs[0].id)
      }
    }).catch(error => {
      reject(error)
    })
  }),
  fetchStoresLIst: ({ commit }) => {
    storesCollection.onSnapshot(snapshot => {
      if (!snapshot.empty) {
        const stores = []
        snapshot.docs.forEach(item => {
          const data = item.data()
          stores.push({
            ...data,
            sid: item.id,
            createdAt: (data.createdAt) ? data.createdAt.toDate() : data.createdAt,
            updatedAt: (data.updatedAt) ? data.updatedAt.toDate() : data.updatedAt,
          })
        })
        commit('setStores', stores)
      }
    })
  },
  createStore: (ctx, { storeName, uid, packages }) => {
    const paymentPackage = packages || ['loan']
    const batch = firebase.db.batch()
    const storesRef = storesCollection.doc()
    const sid = storesRef.id
    batch.set(storesRef, {
      status: 'active',
      storeName,
      storeImage: '',
      themeColor: '',
      groups: [],
      customerStatusAutoChange: [],
      customerStatusExtend: [],
      promissoryNotesStatusExtend: [],
      roles: [],
      accountMembers: [],
      uid,
      users: [uid],
      billing: {
        // paymentDate: new Date(Vue.moment().add(7, 'd')),
        paymentDate: new Date(Vue.moment()),
        paymentMethod: '',
        paymentAmount: 0,
        paymentPackage,
        paymentDuration: 12,
        paymentPlan: 'yearly',
        sales: '',
        affiliate: '',
      },
      display: {
        dashboard: ['processing', 'observed', 'bad_debt', 'closed', 'active', 'inactive', 'dishonored'],
      },
      createdAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
    })
    // packages: collateral, loan, trial
    // paymentDuration: 6 months or 12 months
    // paymentMethod: cash, wire

    const usersRef = usersCollection.doc(uid)
    batch.update(usersRef, {
      updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      [`ability.${sid}`]: manageAbility(paymentPackage),
    })

    batch.commit()
  },
  copyStore: (ctx, params) => {
    const { store, newSid } = params

    if (store && newSid) {
      store.createdAt = firebase.firebase.firestore.FieldValue.serverTimestamp()
      store.updatedAt = firebase.firebase.firestore.FieldValue.serverTimestamp()
      store.from = store.sid
      delete store.sid

      storesCollection.doc(newSid).set(store).catch(err => { console(err) })
    }
  },
  newId: async () => {
    const usersRef = usersCollection.doc()
    return usersRef.id
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
