<template>
  <div>
    <b-card>
      <b-row class="justify-content-center">
        <b-col cols="4">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="searchQuery"
              placeholder="Search Product"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col class="ml-0">
          <b-button
            v-b-modal.create-store-modal
            variant="primary"
            class="btn-icon"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-col>
        <b-col class="text-right float-right">
          <b-button
            variant="primary"
            class="btn-icon"
          >
            <feather-icon icon="CopyIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <StoresTable :table-data="stores" />
    <b-modal
      id="create-store-modal"
      title="Create Store"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      footer-class="justify-content-end flex-row-reverse"
      :ok-title="$t('create')"
      :cancel-title="$t('cancel')"
      cancel-variant="outline-secondary"
      @ok="createStore"
    >
      <b-row>
        <b-col>
          <b-form-group
            label="store name"
            label-for="store-name"
          >
            <b-form-input
              id="store-name"
              v-model="store.storeName"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="user email"
            label-for="user-eamil"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="store.email"
                placeholder="Search"
                @keydown.enter.native="findUser()"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          {{ store.uid }}
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import {
  BCard, BRow, BCol, VBModal, BInputGroup, BFormInput, BInputGroupAppend, BButton, BModal, BFormGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AdminModule from '@/store/admin/adminModule'
import StoresTable from './AdminStoresTable.vue'

export default {
  components: {
    BModal,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    StoresTable,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      store: {
        storeName: null,
        uid: null,
        email: null,
      },
      searchQuery: null,
      storeData: null,
    }
  },
  computed: {
    ...mapGetters({
      stores: 'storesList',
    }),
  },
  beforeCreate() {
    this.$store.registerModule('admin', AdminModule)
  },
  mounted() {
    this.$store.dispatch('fetchStoresLIst')
  },
  beforeDestroy() {
    this.$store.unregisterModule('admin')
  },
  methods: {
    searchStores() {
      store.dispatch('').then(result => {
        this.storeData = result
      })
    },
    createStore() {
      if (this.store.storeName && this.store.uid) {
        store.dispatch('createStore', this.store)
      }
    },
    findUser() {
      store.dispatch('getUserInfo', this.store.email).then(id => {
        if (id) this.store.uid = id
      })
    },
  },
}
</script>
