<template>
  <div>
    <b-table
      :items="tableData"
      :fields="fields"
      striped
      responsive
      @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)"
    >
      <template #row-details="row">
        <b-card>
          <vue-json-pretty
            :show-length="true"
            :show-line="true"
            :collapsed-on-click-brackets="true"
            :select-on-click-node="true"
            :data="row.item"
          />
        </b-card>
      </template>

      <template #cell(avatar)="data">
        <b-avatar :src="data.value" />
      </template>

      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(createdAt)="data">
        {{ data.value | moment('YYYY/MM/DD HH:mm') }}
      </template>
      <template #cell(updatedAt)="data">
        {{ data.value | moment('YYYY/MM/DD HH:mm') }}
      </template>
      <template #cell(action)="data">
        <b-dropdown
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          toggle-class="px-0 py-50 bg-transparent"
          variant="flat-dark"
          no-caret
          right
          @show="customerSelectHandle(data.item)"
        >
          <template
            v-slot:button-content
          >
            <feather-icon
              :id="`box-pop-menu-${data.item.id}`"
              icon="MoreVerticalIcon"
              size="18"
              class="cursor-pointer"
            />
          </template>
          <b-dropdown-item
            v-b-modal.copy-store-modal
          >
            {{ $t('copy') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <b-modal
      id="copy-store-modal"
      title="Copy Store"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      footer-class="justify-content-end flex-row-reverse"
      :ok-title="$t('create')"
      :cancel-title="$t('cancel')"
      cancel-variant="outline-secondary"
      @ok="handleSubmit"
    >
      <b-row>
        <b-col>
          <b-form-group
            label="store name"
            label-for="store-name"
          >
            <b-form-input
              id="store-name"
              v-model="storeData.storeName"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Store Id"
            label-for="store-id"
          >
            <b-form-input
              id="store-id"
              v-model="storeData.sid"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            size="32"
            class="btn-icon rounded-circle mr-1"
            @click="reverseString"
          >
            <feather-icon icon="RepeatIcon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            size="32"
            class="btn-icon rounded-circle"
            @click="autoGenerate"
          >
            <feather-icon icon="PlusSquareIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="New Store Id"
            label-for="new-store-id"
          >
            <b-form-input
              id="new-store-id"
              v-model="form.newStoreId"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable, BCard, BAvatar, BBadge, BModal, BDropdownItem, BDropdown, BRow, BCol, BFormInput, BFormGroup, BButton,
} from 'bootstrap-vue'
import VueJsonPretty from 'vue-json-pretty'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import 'vue-json-pretty/lib/styles.css'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BTable,
    BCard,
    BBadge,
    BModal,
    BAvatar,
    BDropdown,
    BDropdownItem,
    VueJsonPretty,
  },
  directives: { /*
    'b-modal': VBModal, */
    Ripple,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: ['storeName', 'sid', { key: 'avatar', label: 'Avatar' }, 'phoneNumber', 'email', { key: 'status', label: 'Status' }, 'createdAt', 'updatedAt', 'action'],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      storeData: {},
      form: {},
    }
  },
  methods: {
    customerSelectHandle(data) {
      this.$set(this, 'storeData', data)
    },
    reverseString() {
      this.$set(this.form, 'newStoreId', this.storeData.sid.split('').reverse().join(''))
    },
    autoGenerate() {
      store.dispatch('newId').then(id => {
        this.$set(this.form, 'newStoreId', id)
      })
    },
    handleSubmit() {
      store.dispatch('copyStore', { store: this.storeData, newSid: this.form.newStoreId })
    },
  },
}
</script>
